.statsPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3rem 1rem;
}

.headerSection {
  text-align: center;
  margin-bottom: 3rem;
  max-width: 800px;
}

.pageTitle {
  position: relative;
  color: white;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  
  span {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: rgb(255, 128, 0);
      border-radius: 2px;
    }
  }
}

.pageDescription {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
}

.gamemodesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin-top: 1rem;
}

.gamemodeCard {
  width: 100%;
  max-width: 500px;
  border-radius: 12px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
  }
}

.gamemodeDescription {
  padding: 1.5rem;
  
  h3 {
    color: rgb(255, 128, 0);
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1rem;
    line-height: 1.5;
  }
} 