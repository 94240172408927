.statisticContainer {
  width: 100%;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  margin-bottom: 0.8rem;
  transition: transform 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
  
  &:hover {
    transform: translateY(-2px);
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.statName {
  font-weight: 600;
  color: white;
  margin-bottom: 0.8rem;
  text-align: center;
  font-size: 1.1rem;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 2px;
    background-color: rgba(255, 128, 0, 0.6);
  }
}

.levelInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.levelNumber, .nextLevel {
  font-weight: 700;
  min-width: 30px;
  text-align: center;
}

.levelNumber {
  color: rgb(255, 218, 158);
}

.nextLevel {
  color: rgba(255, 218, 158, 0.6);
}

.progressContainer {
  height: 20px;
  width: 75%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(255, 128, 0, 0.3);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  position: relative;
}

.progressBar {
  height: 100%;
  background: linear-gradient(to right, rgb(255, 128, 0), rgb(255, 170, 0));
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: width 0.5s ease;
  min-width: 5px; /* Ensure the bar is always at least minimally visible */
}

.progressText {
  padding: 0 8px;
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  white-space: nowrap;
}

.externalProgressText {
  position: absolute;
  left: 10px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
} 