.category {
    color: rgb(255, 128, 0);
    font-weight: 700;
    font-size: 2.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    display: inline-block;
    
    &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 80px;
        height: 4px;
        background-color: rgb(255, 128, 0);
        border-radius: 2px;
    }
}

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 1.5rem;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.record {
    border-bottom: 1px solid rgba(255, 128, 0, 0.3);
    transition: transform 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease;
    border-radius: 8px;
    margin-bottom: 0.5rem;
    cursor: pointer;
    
    &:hover {
        background-color: rgba(255, 128, 0, 0.1);
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    }

    &:last-child {
        border-bottom: none;
    }
    
    &:active {
        transform: translateY(0);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 128, 0, 0.15);
    }
}

.topRank {
    font-size: 1.25rem;
    font-weight: 800;
}

.rank1 {
    color: gold;
}

.rank2 {
    color: silver;
}

.rank3 {
    color: #cd7f32; /* bronze */
}

.avatar {
    transition: transform 0.2s ease;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    
    &:hover {
        transform: scale(1.1);
    }
}

.username {
    font-weight: 600;
    transition: all 0.2s ease;
    cursor: pointer;
    position: relative;
    
    &:hover {
        color: rgb(255, 128, 0);
        transform: translateY(-1px);
    }
    
    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        bottom: -3px;
        left: 50%;
        background-color: rgb(255, 128, 0);
        transition: all 0.2s ease;
    }
    
    &:hover::after {
        width: 70%;
        left: 15%;
    }
}

.value {
    font-weight: 700;
    color: rgb(255, 218, 158);
}