.footerWrapper {
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 3rem;
}

.whyUsSection {
  max-width: 1200px;
  margin: 0 auto 3rem;
  padding: 0 1.5rem;
}

.whyUsContent {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
}

.whyUsHeading {
  color: rgb(255, 128, 0);
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 80px;
    height: 3px;
    background-color: rgb(255, 128, 0);
    border-radius: 2px;
  }
}

.whyUsIcon {
  color: rgb(255, 128, 0);
  font-size: 1.5rem;
}

.whyUsParagraph {
  color: #D8D8D8;
  font-weight: 300;
  line-height: 1.6;
  font-size: 1.1rem;
}

.footerContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem 3rem;
}

.footerGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.footerBranding {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footerLogo {
  height: 60px;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: transform 0.2s ease;
  
  &:hover {
    transform: scale(1.05);
  }
}

.copyright {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.socialLinks {
  display: flex;
  gap: 1rem;
}

.socialIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: rgb(255, 128, 0);
    transform: translateY(-3px);
  }
}

.footerLinks {
  display: flex;
  flex-direction: column;
}

.footerHeading {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 40px;
    height: 2px;
    background-color: rgb(255, 128, 0);
    border-radius: 2px;
  }
}

.footerHeadingIcon {
  color: rgb(255, 128, 0);
  font-size: 1rem;
}

.footerLinks ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  
  li {
    margin-bottom: 0.8rem;
    transition: transform 0.2s ease;
    cursor: pointer;
    
    &:hover {
      transform: translateX(5px);
    }
    
    a {
      color: rgba(255, 255, 255, 0.8);
      text-decoration: none;
      transition: color 0.2s ease;
      
      &:hover {
        color: rgb(255, 128, 0);
      }
    }
  }
}

.footerBottom {
  width: 100%;
  padding: 1.5rem;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 768px) {
  .footerGrid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
  }
  
  .footerBranding {
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .footerLinks {
    align-items: center;
    text-align: center;
  }
  
  .footerHeading {
    justify-content: center;
    
    &::after {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  
  .footerLinks ul li {
    &:hover {
      transform: translateY(-2px);
    }
  }
} 