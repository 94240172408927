.socialMediaSection {
  background: linear-gradient(135deg, #ffffff 0%, #f5f5f5 100%);
  border-radius: 20px;
  padding: 50px 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background: linear-gradient(90deg, #ff7e00, #ff4d00);
  }
}

.sectionTitle {
  font-size: 2.5rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 0.5rem;
  background: linear-gradient(90deg, #ff7e00, #ff4d00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  position: relative;
  display: inline-block;
}

.sectionSubtitle {
  text-align: center;
  color: #666;
  font-weight: 400;
  font-size: 1.1rem;
  margin-bottom: 2.5rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.contentContainer {
  position: relative;
  z-index: 1;
}

.divider {
  width: 80px;
  height: 4px;
  background: linear-gradient(90deg, #ff7e00, #ff4d00);
  margin: 0 auto 2.5rem;
  border-radius: 2px;
}

.socialTabs {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
}

.socialTab {
  padding: 0.8rem 1.5rem;
  margin: 0 0.5rem;
  font-weight: 600;
  color: #555;
  cursor: pointer;
  border-radius: 30px;
  transition: all 0.3s ease;
  background: transparent;
  border: none;
  position: relative;
  
  &.active {
    color: #ff7e00;
    background: rgba(255, 126, 0, 0.1);
    
    &::after {
      width: 100%;
    }
  }
  
  &:hover {
    background: rgba(255, 126, 0, 0.05);
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: 0;
    width: 0;
    height: 3px;
    background: #ff7e00;
    transition: width 0.3s ease;
  }
}

.content {
  padding: 1rem;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  /* Hide scrollbars but keep functionality */
  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }
  
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 128, 0, 0.1);
    border-radius: 10px;
    
    &:hover {
      background-color: rgba(255, 128, 0, 0.2);
    }
  }
  
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 128, 0, 0.1) transparent;
}

.platformIcon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.facebookContainer {
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease;
  min-height: 500px;
  position: relative;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  
  &:hover {
    transform: translateY(-5px);
  }
}

.loaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  z-index: 1;
  
  p {
    margin-top: 1rem;
    color: #fff;
    font-size: 0.9rem;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid rgba(255, 128, 0, 0.2);
  border-bottom-color: rgb(255, 128, 0);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tiktokContainer {
  width: 100%;
  min-height: 650px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 800px;
  
  /* Custom Scrollbar Styling */
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    transition: background-color 0.3s;
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
  
  /* Firefox scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0.1);
  
  /* Apply scrollbar styling to all content inside */
  * {
    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }
    
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 10px;
    }
    
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
  }
}

.tiktokGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding-bottom: 20px;
}

@media (max-width: 768px) {
  .socialTabs {
    flex-direction: column;
    align-items: center;
  }
  
  .socialTab {
    margin-bottom: 0.5rem;
    width: 80%;
  }
  
  .sectionTitle {
    font-size: 2rem;
  }
} 