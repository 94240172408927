.username {
    color: rgb(255, 128, 0);
    font-weight: 700;
    font-size: 2rem;
    position: relative;
    margin-bottom: 1rem;
    
    &::after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 3px;
        background-color: rgb(255, 128, 0);
        border-radius: 2px;
    }
}

.mainContainer {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.playerCard {
    width: 100%;
    max-width: 900px;
    padding: 2rem;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    margin-bottom: 2rem;
    transition: transform 0.2s ease;
    
    &:hover {
        transform: translateY(-5px);
    }
}

.avatar {
    border-radius: 50%;
    border: 3px solid rgb(255, 128, 0);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
    
    &:hover {
        transform: scale(1.05);
    }
}

.playerClass {
    color: rgb(255, 218, 158);
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.statsContainer {
    width: 100%;
    max-width: 1200px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    
    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.statsSection {
    width: 100%;
    padding: 1.5rem;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.25);
    margin-bottom: 1.5rem;
    transition: transform 0.2s ease;
    
    @media (min-width: 768px) {
        width: 48%;
        margin-bottom: 0;
    }
    
    &:hover {
        transform: translateY(-3px);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    }
    
    h3 {
        color: rgb(255, 128, 0);
        margin-bottom: 1.5rem;
        font-weight: 600;
        text-align: center;
        position: relative;
        
        &::after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
            width: 40px;
            height: 2px;
            background-color: rgb(255, 128, 0);
        }
    }
}

.backButton {
    background-color: transparent;
    color: rgb(255, 128, 0);
    border: 1px solid rgb(255, 128, 0);
    border-radius: 20px;
    padding: 0.5rem 1.5rem;
    font-weight: 600;
    transition: all 0.2s ease;
    
    &:hover {
        background-color: rgb(255, 128, 0);
        color: white;
        transform: translateY(-2px);
    }
}