.container {
  position: relative;
  margin-top: 2rem;
  border-radius: 15px;
  margin-bottom: 2rem;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  position: relative;
  display: inline-block;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background-color: rgb(255, 128, 0);
    border-radius: 2px;
  }
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}

.gameModesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  width: 100%;
  margin-bottom: 1.5rem;
}

.column {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.cardContainer {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  background-color: rgba(18, 23, 48, 0.5);
  
  &:hover {
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
  }
}

.videoWrapper {
  position: relative;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  border-radius: 12px;
  
  video, img {
    width: 100%;
    display: block;
    transition: transform 0.5s ease;
  }
  
  &:hover {
    .gamemodeName {
      background-color: rgba(255, 128, 0, 0.9);
    }
  }
}

.resizedVideo {
  object-fit: cover;
  height: 100%;
  max-height: 350px; /* Increased from 250px to make videos larger */
  width: 100%;
  aspect-ratio: 16 / 9; /* Maintains a consistent aspect ratio */
}

.gamemodeName {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background-color: rgba(18, 23, 48, 0.6);
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  transition: all 0.3s ease;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  
  div {
    position: relative;
    display: inline-block;
    
    &:after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 0;
      height: 2px;
      background-color: white;
      transition: width 0.3s ease;
    }
  }
  
  &:hover div:after {
    width: 100%;
  }
}

.extraModes {
  overflow: hidden;
  padding: 0.5rem;
}

.toggleButton {
  background-color: rgb(255, 128, 0);
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 1.5rem auto;
  display: inline-block;
  width: auto;
  box-shadow: 0 4px 15px rgba(255, 128, 0, 0.3);
  
  &:hover {
    background-color: rgba(255, 128, 0, 0.9);
    box-shadow: 0 6px 20px rgba(255, 128, 0, 0.4);
  }
} 