.gamemodeInfo {
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    border-radius: 8px 8px 0 0;
    
    &:hover {
        cursor: pointer;
        
        video, img {
            transform: scale(1.05);
        }
        
        .gameTitle {
            background-color: rgba(255, 128, 0, 0.8);
        }
    }
    
    video, img {
        display: block;
        width: 100%;
        height: 280px;
        object-fit: cover;
        transition: transform 0.5s ease;
    }
}

.gameTitle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    transition: background-color 0.3s ease;
}