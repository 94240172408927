.broadcastContainer {
  position: relative;
  width: 100%;
  z-index: 999;
  background-color: rgba(18, 23, 48, 0.95);
  padding: 10px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.broadcastContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.message {
  color: white;
  font-weight: 600;
  font-size: 1rem;
  flex-grow: 1;
  text-align: center;
}

.highlight {
  color: rgb(255, 128, 0);
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    color: rgb(255, 160, 80);
    text-decoration: underline;
  }
}

.buttons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.joinButton {
  background-color: rgb(255, 128, 0);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: rgba(255, 128, 0, 0.8);
  }
}

.closeButton {
  background: none;
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0 8px;
  opacity: 0.7;
  
  &:hover {
    opacity: 1;
  }
} 