.searchButton {
    background-color: rgb(255, 128, 0);
    transition: all 0.2s ease;
    padding: 0.5rem 1.25rem;
    
    &:hover {
        background-color: darken(rgb(255, 128, 0), 10%);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
    
    &:active {
        transform: translateY(2px);
    }
}

.searchBox {
    outline: none;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    border: 2px solid transparent !important;
    padding: 0.75rem 1rem !important;
    font-size: 1rem;
    transition: all 0.2s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    
    &:focus {
        background-color: rgba(0, 0, 0, 0.3);
        border-color: rgba(255, 128, 0, 0.5) !important;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    }
    
    &::placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
}

.searchContainer {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    max-width: 600px;
    width: 100%;
}