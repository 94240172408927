.topWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 1rem;
  margin: 0.5rem;
  min-width: 120px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.topPosition {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.rank1 {
  color: gold;
  font-size: 1.8rem;
  
  .medal {
    position: relative;
    
    &::before,
    &::after {
      content: '★';
      position: absolute;
      top: 0;
      font-size: 0.6em;
    }
    
    &::before {
      left: -0.8em;
    }
    
    &::after {
      right: -0.8em;
    }
  }
}

.rank2 {
  color: silver;
  font-size: 1.6rem;
}

.rank3 {
  color: #cd7f32; /* Bronze */
  font-size: 1.5rem;
}

.category {
  font-size: 0.9rem;
  text-align: center;
  color: rgb(255, 218, 158);
  font-weight: 500;
} 