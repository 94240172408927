.navbarContainer {
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 0.5rem 2rem;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
}

.navbarContent {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoSection {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.logo {
  height: 70px;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
}

.onlineInfo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  font-size: 0.9rem;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    transform: translateY(-2px);
  }

  span {
    white-space: nowrap;
  }
}

.pulse {
  animation: pulse 2s infinite;
  background-color: #4caf50;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.navigation {
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 0.5rem;
  }

  li {
    position: relative;
    transition: all 0.3s ease;
    
    &:hover {
      transform: translateY(-2px);
    }
    
    &.active {
      &::after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: rgb(255, 128, 0);
        border-radius: 3px;
      }
    }
  }
}

.navLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  text-decoration: none;
  padding: 0.7rem 1rem;
  border-radius: 8px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba(255, 128, 0, 0.1);
    color: rgb(255, 128, 0);
  }
}

.navIcon {
  font-size: 1.2rem;
  opacity: 0.8;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.navLink:hover .navIcon {
  opacity: 1;
  transform: translateY(-2px);
}

.mobileMenuButton {
  display: none;
  background: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    color: rgb(255, 128, 0);
    transform: rotate(90deg);
  }
}

/* Mobile Styles */
@media (max-width: 1024px) {
  .navbarContainer {
    padding: 0.5rem 1rem;
  }
  
  .mobileMenuButton {
    display: block;
    z-index: 1001;
  }
  
  .mobileNav {
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    max-width: 300px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);
    transition: right 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1000;
    
    ul {
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    }
    
    li {
      width: 100%;
      text-align: center;
      
      &.active::after {
        width: 50px;
        left: calc(50% - 25px);
      }
    }
    
    .navLink {
      justify-content: center;
      padding: 1rem;
    }
    
    a.navLink, button.navLink {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
  
  .mobileNavOpen {
    right: 0;
  }
  
  .logoSection {
    gap: 0.5rem;
  }
  
  .onlineInfo {
    font-size: 0.8rem;
    padding: 0.3rem 0.7rem;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(76, 175, 80, 0);
  }
  
  100% {
    transform: scale(0.9);
  }
} 