.serverInfoContainer {
  padding: 2rem 1rem;
  background-color: transparent;
  border-radius: 15px;
}

.sectionTitle {
  color: #fff;
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 700;
  font-size: 2.5rem;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background-color: rgb(255, 128, 0);
    border-radius: 2px;
  }
}

.infoGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  width: 100%;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.infoCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.5rem;
  transition: all 0.3s ease;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 128, 0, 0.2);
  box-shadow: 0 0 20px rgba(209, 114, 19, 0.1);
  
  &:hover {
    background-color: rgba(18, 5, 1, 0.7);
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(209, 114, 19, 0.2);
    border: 1px solid rgba(255, 128, 0, 0.4);
    
    .icon {
      color: rgb(255, 128, 0);
      transform: scale(1.1);
    }
  }
}

.iconContainer {
  margin-bottom: 1.5rem;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 128, 0, 0.1);
  border: 2px solid rgba(255, 128, 0, 0.3);
}

.icon {
  font-size: 2.2rem;
  color: rgb(255, 255, 255);
  transition: all 0.3s ease;
}

.infoTitle {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.infoDescription {
  color: #D8D8D8;
  font-size: 1rem;
  line-height: 1.5;
} 